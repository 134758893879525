/* resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

/* imports */
@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Lato&family=Ubuntu+Mono&display=swap');

/* styles */

body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: whitesmoke;
}

.title {
    text-align: center;
    font-family: "Lato";
}

.wrapper {
    display: flex;
    margin: 5px auto;
    min-height: calc(90vh - 5px);
    gap: 5px;
    padding: 5px;
    justify-content: space-between;

    >* {
        width: 50%;
        flex-grow: 1;
        margin-bottom: 300px;
        border-radius: 20px;
        padding: 20px;
        overflow: hidden;
        border: 0.5rem solid rgb(187, 151, 192);
    }
}

#editor {
    background-color: darkslategray;
    color: lightgray;
    font-family: 'Ubuntu Mono', monospace;
}

#preview {
    font-family: 'Crimson Pro', serif;
}

/* media queries */

@media (max-width: 900px) {
    .wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        >* {
            margin-bottom: 10px;
            width: 100%;
            height: 80vh;
            max-width: 100vw;
            overflow-x: scroll;
        }
    }
}